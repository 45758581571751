@media (max-width: 767px) {
  .elementor-2777 .elementor-element.elementor-element-73798c1 {
    width: 58%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-2777 .elementor-element.elementor-element-73798c1 {
    width: 73%;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .elementor-2755 .elementor-element.elementor-element-07d3424,
  .elementor-2755 .elementor-element.elementor-element-6c437d3 {
    width: 100% !important;
  }
}
@media (max-width: 1024px) {
  .elementor-element-d1980c9 {
    width: 100% !important;
  }
}
@media (min-width: 1024px) {
  .elementor-widget-wrap-2 {
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
@media (max-width: 768px) {
  .elementor-widget-wrap-2 {
    padding: 30px !important;
  }
  .aa-form-control {
    width: 100% !important;
    padding: unset !;
    padding-right: unset !important;
    margin-bottom: 30px !important;
  }
}
.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

/*define the modal-content background*/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  background-color: #fefefe;
  margin: 0;
  border: 0px solid #888;
  width: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  box-shadow: unset;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.5rem 1rem !important;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 0rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

/*add full-width input fields*/
.modal-content input[type="text"],
.modal-content input[type="password"],
.modal-content input[type="tel"],
.modal-content select,
.modal-content input[type="email"],
.modal-content textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 15px;
}

/*define the close button*/

.close {
  position: absolute;
  right: 15px;
  color: #fff;
  font-size: 40px;
}

.close:hover,
.close:focus {
  color: red;
  cursor: pointer;
}
#inquiry_modal label {
  color: #00214b;
  font-weight: 600;
}
.elementor-2755 .elementor-element.elementor-element-9c97256 {
  padding: 12em 1em 1em 1em;
}
.whatsapp_btn {
  position: fixed;
  right: 45px;
  bottom: 100px;
  width: 54px;
  z-index: 9999;
}

.slick-list {
  transform: translate3d(-629px, 0px, 0px);
  transition-duration: 0ms;
}
@media (min-width: 1024px) {
  body:not(.rtl)
    .elementor-2777
    .elementor-element.elementor-element-e41f82d
    .hfe-nav-menu__layout-horizontal
    .hfe-nav-menu
    > li.menu-item:not(:last-child) {
    margin: auto;
    padding-right: 25px;
  }
}

@media (max-width: 1024px) {
  .ekit-wid-con-none {
    display: none;
  }
  .hfe-creative-menu-none {
    display: inline-flex;
    padding: 8px !important;
    justify-content: end;
  }
  .ekit-wid-con-8px {
    padding-right: 8px !important;
  }
}
@media (min-width: 1024px) {
  .hfe-creative-menu-none {
    display: none;
  }
}
